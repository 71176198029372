import remark from 'remark'
import remarkHtml from 'remark-html'
import { Plugin } from 'unified';
import { Node } from 'unist';

const markdownToHtml = (content: string) => {
  const transformH1ToH2: Plugin = () => {
    return (tree: Node) => {
      const visit = (node: Node) => {
        if (node.type === 'heading' && 'depth' in node && node.depth === 1) {
          node.depth = 2; // Change h1 to h2
        }
        if ('children' in node && Array.isArray(node.children)) {
          node.children.forEach(visit); // Recursively visit children
        }
      };
      visit(tree);
    };
  };

  return remark()
    .use(transformH1ToH2)
    .use(remarkHtml)
    .processSync(content)
    .contents.toString()
}

export default markdownToHtml
